@layer components {
  .btn {
    @apply relative inline-flex min-h-[54px] max-w-fit flex-col items-center justify-center rounded-[6px] px-[10px] py-[10px] font-base text-[18px] font-normal text-white transition duration-200 ease-linear;
    span {
      @apply text-[18px];
    }
    &:hover {
      @apply transition duration-200 ease-linear;
    }
  }

  .btn-primary {
    @apply border-2 border-solid border-main-purple-500 bg-main-purple-500 text-white;
    &:hover {
      @apply border-main-purple-700 bg-main-purple-700;
    }
  }

  .btn-primary-light {
    @apply border-2 border-solid border-main-purple-400 bg-main-purple-400 text-white;
    &:hover {
      @apply border-main-purple-700 bg-main-purple-700 text-white;
    }
  }

  .btn-text-primary {
    @apply border-2 border-solid border-main-purple-500 text-main-purple-400;
    &:hover {
      @apply border-main-purple-800 bg-main-purple-800 text-white;
    }
  }

  .btn-black {
    @apply border-2 border-solid border-main-dark-blue-500 bg-main-dark-blue-500 text-white;
    &:hover {
      @apply border-main-purple-400 bg-main-purple-400 text-white shadow-button;
    }
  }

  .btn-reset {
    @apply border-none bg-none shadow-none outline-none focus:border-none focus:bg-none focus:shadow-none focus:outline-none;
  }

  .btn-purple-bordered {
    @apply border-[1px] border-main-purple-500 bg-transparent text-white;
    span {
      @apply text-white;
    }
    &:hover {
      @apply bg-main-purple-500;
    }
  }

  .btn-red {
    @apply border-2 border-solid border-main-red-500 bg-main-red-500 text-white;
    &:hover {
      @apply border-main-purple-500 bg-main-purple-500 text-white;
    }
  }
}
