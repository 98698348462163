* {
  padding: 0;
  margin: 0;
  border: 0;
  box-sizing: border-box;
}

*:before,
*:after {
  box-sizing: border-box;
}

:focus,
:active {
  outline: none;
}

a:focus,
a:active {
  outline: none;
}

nav,
footer,
header,
aside {
  display: block;
}

html {
  min-height: 100%;
  scroll-behavior: smooth;
  overflow-x: hidden;
}
body {
  width: 100%;
  min-height: 100vh;
}

input,
button,
textarea {
  font-family: inherit;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a {
  text-decoration: none;

  &:visited {
    text-decoration: none;
  }

  &:hover {
    text-decoration: none;
  }
}

ul,
li {
}

img {
  vertical-align: top;
  max-width: 100%;
  display: inline-block;
  height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  margin: 0;
  padding: 0;
  font-weight: 400;
}

sup {
  color: inherit;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
