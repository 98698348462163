@layer components {
  a {
    @apply transition-all duration-[300ms] ease-linear;

    &:hover {
      @apply transition-all duration-[300ms] ease-linear;
    }
  }
  a.purple {
    @apply text-main-purple-400 underline hover:text-white hover:underline;
  }
  a.purple-inverse {
    @apply text-white underline hover:text-main-purple-400 hover:underline;
  }
}
