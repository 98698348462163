.content {
  @apply text-[16px] leading-[1.55];

  p {
    @apply mb-[23px] lg:mb-[30px];
  }

  h2, h5 {
    @apply mb-5;
  }

  h2 + *,
  * + ul,
  ul + *,
  * + ol,
  ol + * {
    @apply mb-[23px] lg:mb-[30px];
  }

  * + h2 {
    @apply mb-[38px] lg:mb-[50px];
  }

  h2 {
    @apply text-[32px] font-bold lg:text-[34px];
  }

  ul {
    @apply flex list-inside list-disc flex-col gap-[15px] text-white text-opacity-60 mb-4;
    li {
      @apply marker:text-white/60;
    }
  }

  ol {
    @apply flex list-inside list-decimal flex-col gap-[15px] text-white text-opacity-60 mb-4;
    li {
      @apply marker:text-white/60;
    }
  }
}
