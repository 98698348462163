// Firefox
* {
  scrollbar-width: thin;
  scrollbar-color: #000 #d6d6d6;
  // Chrome, Edge, and Safari
  &::-webkit-scrollbar {
    width: 3px;
    background-color: #fff;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px rgba(#000, 20%);
    border-radius: 2px;
    background: #bababa;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    border: 0 none #fff;
    background-color: #333232;
    transition: all 280ms ease-in-out;
    &:hover {
      background-color: #000;
    }
  }
}
