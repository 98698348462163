.section-main-screen {
  @apply min-h-[calc(100vh-87px)];
}

.modal-default {
  max-width: calc(100vw - 40px) !important;
  .mdc-dialog__container {
    .mat-mdc-dialog-surface {
      background: none;
      box-shadow: none;
    }
  }
  .mat-mdc-dialog-surface .mdc-dialog__surface {
    display: none !important;
  }
}
