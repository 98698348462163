.form-group {
  @apply relative flex;
}

.form-control-row {
  @apply relative flex min-h-[54px] w-full rounded-md border border-solid border-transparent bg-main-dark-blue-600 hover:border-main-purple-500;
}

.form-control {
  @apply min-h-[54px] w-full;
}

.form-label {
  @apply absolute left-[18px] top-[11px] text-[10px] text-xs leading-none text-main-purple-400 xl:top-[9px];
}

.form-input {
  @apply min-h-[57px] rounded-[4px] bg-main-dark-blue-300 px-[15px];
  &-light {
    @apply bg-main-gray-800;
  }
  &-black {
    @apply min-h-[54px] bg-main-dark-blue-600;
  }
}

.input-search {
  @apply min-h-[60px] rounded-l-[4px] bg-main-dark-blue-600  transition duration-200 ease-linear lg:w-0 lg:p-0;
  &.active {
    @apply px-[15px] transition duration-200 ease-linear lg:w-[440px];
  }
}

.form-label-static {
  @apply text-[16px] leading-[1] text-white/60;
}

input.form-input::placeholder,
input.input-search::placeholder,
textarea.form-input::placeholder {
  @apply text-[14px] text-white/60 opacity-100 transition duration-200 ease-linear md:text-[16px];
  &:focus {
    @apply opacity-0 transition duration-200 ease-linear;
  }
  &.ng-invalid {
    &.ng-touched {
      @apply text-red-700;
    }
  }
  &.ng-valid {
    &.ng-touched {
      @apply text-white/60;
    }
  }
  &.form-input-white {
    @apply text-white;
  }
}

.form-input-exchange {
  @apply absolute bottom-[14px] left-0 w-full bg-transparent px-[18px] text-xs font-semibold leading-none text-white xl:bottom-[6px] xl:text-lg;
}

.input-params {
  @apply min-h-[54px] rounded border border-transparent bg-main-dark-blue-600 px-[15px] py-3 text-base leading-none text-white transition-all;
  &:hover,
  &:focus {
    @apply border border-main-purple-500;
  }
  &::placeholder {
    @apply text-white opacity-50;
  }
}

.min-w-100perc-100px {
  min-width: calc(100% - 100px);
}

.custom-checkbox {
  @apply relative flex cursor-pointer items-center pl-[26px] md:pl-[32px];

  input {
    @apply hidden;
  }
  .checkmark {
    @apply absolute left-0 top-[3px] h-[18px] w-[18px] rounded-[3px] border border-solid border-white bg-transparent content-none md:top-[0] md:h-[24px] md:w-[24px];

    &:after {
      content: '';
      @apply absolute left-[13px] top-[5px] h-[5px] w-[9px] origin-top-left rotate-[135deg] border-r-2 border-t-2 border-solid border-white opacity-0  md:left-[18px]  md:top-[7px] md:h-[7px] md:w-[12px];
    }
  }
  &-purple {
    .checkmark {
      @apply border-main-purple-400 after:border-main-purple-400;
    }
  }

  input:checked + .checkmark:after {
    @apply opacity-100;
  }
}

.input-invalid {
  @apply text-main-red-500 bg-main-red-800 placeholder:text-main-red-500 placeholder-shown:text-main-red-500;
  background-color: #462B2F !important;
  &::placeholder {
    @apply opacity-100;
  }
  .form-label {
    @apply text-main-red-500;
  }
}

.form-error-text {
  @apply text-main-red-500 text-xs md:text-base relative pl-7 mt-1 flex gap-2 before:bg-no-repeat before:bg-contain before:content-[''] before:absolute before:left-0 before:top-[3px] before:w-[18px] before:h-[18px];
  &:before {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'%3e%3ccircle cx='9' cy='9' r='9' fill='%23EB5757'/%3e%3cpath d='M6.60756 6.60753L11.8226 11.8226M11.8226 6.60753L6.60756 11.8226' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e");
  }
}

.select-indalid {
  @apply text-main-red-500 bg-main-red-800;
  .mat-mdc-select-placeholder {
    color: #EB5757 !important;
    opacity: 1 !important;
  }
}

.mdc-tooltip__surface {
  padding: 10px !important;
  border-radius: 4px;
  border: 1px solid #F4F4F4;
  background: #27292F !important;
  color: #FFFFFF70 !important;
  font-size: 12px !important;
  line-height: 1.2;
  font-weight: 400;
  max-width: 285px !important;
  white-space: pre-line;
  border: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
  background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(0,174,255,0.04) 50%,rgba(255,255,255,0) 51%,rgba(0,174,255,0.03) 100%);  
}

input:-webkit-autofill {
  -webkit-text-fill-color: #FFFFFF !important;
}