.socials {
  @apply flex gap-[8px];
  li {
    a {
      @apply flex h-[52px] w-[52px] flex-col items-center justify-center rounded-[50%] border-[1px] border-solid border-main-purple-500 bg-transparent;
      svg {
        path {
          @apply fill-main-purple-500;
        }
      }
      &:hover {
        @apply bg-main-purple-500;
        svg {
          path {
            @apply fill-white;
          }
        }
      }
    }
  }
}
